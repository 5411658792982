import React, { Component } from 'react'

export default class NotFoundPage extends Component {
  render() {
    return (
      <section className='NotFoundPage'>
        <h2>404 - Page not found</h2>
      </section>
    )
  }
}
