import React, {Component} from 'react';
import PortfolioBuilderForm from "../../components/PortfolioBuilder/PortfolioBuilderForm";

class PortfolioBuilderPage extends Component {
    render() {
        return (
            <div>
                <PortfolioBuilderForm/>
            </div>
        );
    }
}

export default PortfolioBuilderPage;